<template>
    <div class="user-index">
        <div class="main-h1">Создание обращения</div>
        <form class="card" v-on:submit.prevent>
            <div class="card-title">Новое обращение</div>
            <div class="card-body">
                <div class="card-body-title">Название</div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">
                            <div class="t">Произвольное название *</div>
                            <input v-model="appeal.title"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Связанное дело</div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label checkbox flex-st">
                            <input v-model="appeal.isRequest" type="checkbox" value="1"/>
                            <div class="t">Запрос на ознакомление с материалами дела?</div>
                        </label>
                    </div>
                    <div class="col-12">
                        <label class="form-label">
                            <div class="t">Номер связанного дела (обязательно для запроса ознакомления)</div>
                            <input v-model="appeal.claim_id" type="number" min="1"/>
                        </label>
                    </div>
                </div>
            </div>
            <button :disabled="!btnEnabled" class="btn green" @click="createAppeal">Создать</button>
        </form>
    </div>
</template>

<script>
export default {
    name: "create",
    data() {
        return {
            appeal:{
                title:'',
                isRequest:0,
                claim_id: '',
            },
            requestInProgress: false,
        }
    },
    methods: {
        createAppeal() {
            this.requestInProgress = true;
            this.$api.post(`appeal_user`,this.appeal).then(res => {
                if (res.data.success) {
                    this.appeal = res.data.data;
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Обращение успешно создано.',type:'success'});
                    this.$router.push(`/panel/appeal/${res.data.data.appeal_id}/edit_user`);
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
                this.requestInProgress = false;
            })
        },
    },
    created() {
        if (this.$route.params && this.$route.params.claim_id) {
            this.appeal.claim_id = this.$route.params.claim_id;
        }
    },
    computed: {
        btnEnabled: function () {
            return this.appeal.title.length > 0 && !this.requestInProgress;
        }
    }
}
</script>

<style scoped lang="scss">
.form-label {
    &.checkbox {
        margin: 0 0 10px;
        input[type="checkbox"] {display: inline-block;width: auto;}
        .t {margin: 0 0 0 5px;}
    }
}
</style>